<template>
<div class="media">
  <div class="banner">
    <div class="banner-text">
      <p class="title">最新报道与活动</p>
    </div>
  </div>
  <section>
    <main>
      <div class="media-box" v-for="media in mediaList" :key="media.title" @click="toLink(media.url)">
        <img :src="media.img" :alt="media.title" style="width: 250px; height: 140px;">
        <div class="media-item">
          <div>
            <p class="media-item__title">{{media.title}}</p>
            <p class="media-item__desc">{{media.content}}</p>
          </div>
          <p class="media-item__bottom">
            <span>{{media.time}}</span>
            <span>{{media.origin}}</span>
          </p>
        </div>
      </div>
    </main>
  </section>
</div>
</template>
<script>
export default {
  name: 'media',
  data () {
    return {
      mediaList: [
        {
          img: 'https://images.zzt.com.cn/ox/2022/12/08/dynamic_1.png',
          title: '中国企业科技力量化评测联合课题组成立',
          content: '中国技术市场协会、中国知识产权研究会、新华社《经济参考报》、知识产权出版社与中知数通（北京）信息技术有限公司日前成立联合课题组，共同开展“中国企业科技力量化评测”研究项目。',
          time: '2022-11-28',
          origin: '来源：中知数通',
          url: 'https://mp.weixin.qq.com/s/MvT6Yy7sAwpuHwxp1m52wQ'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/11/03/media1.png',
          title: '国内首个知识产权数字化流通生态平台建设启动',
          content: '国家知识产权局直属单位和知识产权信息服务专业机构知识产权出版社有限责任公司与在线资产交易平台阿里资产达成数字化战略合作，联合知识产权大数据应用服务商中知数通、新型数字科技企业繁星超越共同打造知识产权数字化流通生态平台。',
          time: '2022-09-09',
          origin: '来源：经济参考报',
          url: 'http://www.jjckb.cn/2022-09/09/c_1310661059.htm'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/11/03/media2.png',
          title: '专利大数据应用系统“深眸”上线',
          content: '由国家知识产权局知识产权出版社、中知数通（北京）信息技术有限公司联合打造的专利大数据应用系统“深眸”日前正式发布。',
          time: '2022-04-28',
          origin: '来源：经济参考报',
          url: 'http://www.jjckb.cn/2022-04/28/c_1310577086.htm'
        },
        {
          img: 'https://images.zzt.com.cn/ox/2022/11/03/media3.png',
          title: '国家知识产权局知识产权出版社与中知数通签署战略合作协议',
          content: '国家知识产权局知识产权出版社日前与中知数通(北京)信息技术有限公司签署战略合作协议，双方将共建基于国家知识产权局知识产权出版社权威数据的中知通知识产权金融创新服务平台，为开展知识产权金融服务的机构保驾护航。',
          time: '2022-01-13',
          origin: '来源：中国财经网',
          url: 'http://finance.china.com.cn//roll/20220113/5728010.shtml '
        }
      ]
    }
  },
  methods: {
    toLink (url) {
      window.open(url, { target: '_blank' })
    }
  }
}
</script>

<style lang="scss" scoped>
.media {
  background-color: #fff;
}
.banner {
  width: 100%;
  height: 914px;
  background: url("https://images.zzt.com.cn/ox/2022/11/03/banner_media.png") no-repeat;
  background-position: center center;
  background-size: cover;
  &-text {
    width: $container-width;
    margin: 0 auto;
    position: relative;
    .title {
      position: absolute;
      top: Translate(142px);
      left: 0;
      font-family: 'PingFangSC-Medium';
      font-size: Translate(40px);
      color: #FFFFFF;
      font-weight: 500;
      letter-spacing: 1px;
      &::after {
        position: absolute;
        bottom: -60px;
        left: 0;
        display: inline-block;
        content: "";
        width: 136px;
        height: Translate(4px);
        background-color: rgba($color: #fff, $alpha: .7);
      }
    }
  }
}
section {
  width: $container-width;
  margin: 0 auto;
  main {
    padding: 140px 0;
  }
  .media-box {
    cursor: pointer;
    img {
      margin-right: 66px;
    }
    display: flex;
    margin-bottom: 94px;
    .media-item {
      p {
        margin: 24px 0;
      }
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      text-align: left;
      &__title {
        font-family: 'PingFangSC-Semibold';
        font-size: 46px;
        color: #333333;
        line-height: 52px;
        font-weight: 600;
      }
      &__desc {
        font-family: 'PingFangSC-Regular';
        font-size: 34px;
        color: #888;
        line-height: 56px;
        font-weight: 400;
      }
      &__bottom {
        font-family: 'PingFangSC-Regular';
        font-size: 28px;
        color: #666;
        line-height: 28px;
        font-weight: 400;
        span {
          display: inline-block;
          margin-right: 46px;
        }
      }
    }
  }
}
</style>
